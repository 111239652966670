import { render, staticRenderFns } from "./DataView.vue?vue&type=template&id=00e2e314&"
import script from "./DataView.vue?vue&type=script&lang=js&"
export * from "./DataView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2378092541/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00e2e314')) {
      api.createRecord('00e2e314', component.options)
    } else {
      api.reload('00e2e314', component.options)
    }
    module.hot.accept("./DataView.vue?vue&type=template&id=00e2e314&", function () {
      api.rerender('00e2e314', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/plugins/viewer/data/DataView.vue"
export default component.exports