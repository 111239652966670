import { render, staticRenderFns } from "./BackgroundEffects.vue?vue&type=template&id=5e9e3519&scoped=true&"
import script from "./BackgroundEffects.vue?vue&type=script&lang=js&"
export * from "./BackgroundEffects.vue?vue&type=script&lang=js&"
import style0 from "./BackgroundEffects.vue?vue&type=style&index=0&id=5e9e3519&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e9e3519",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2378092541/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e9e3519')) {
      api.createRecord('5e9e3519', component.options)
    } else {
      api.reload('5e9e3519', component.options)
    }
    module.hot.accept("./BackgroundEffects.vue?vue&type=template&id=5e9e3519&scoped=true&", function () {
      api.rerender('5e9e3519', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/room/BackgroundEffects.vue"
export default component.exports